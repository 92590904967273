import React from "react";

function AdvancePayment() {
  return (
    <div className="advance_payment_main">
      <div className="booking_price_details">
        <p className="advance_payment_text">Before you stay you'll pay</p>
        <p className="advance_payment_price">US$4,047</p>
        {/* jdhfjhdjfhjdhfhd */}
      </div>
    </div>
  );
}

export default AdvancePayment;
